import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`12th February 2020`}</p>


    <h2 {...{
      "id": "-before-we-begin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-before-we-begin",
        "aria-label": " before we begin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🎬 Before we begin`}</h2>
    <p>{`This release contains more breaking changes and deprecations than usual, which were necessary for the preparation of 1.0 release and the long term evolution of our API after 1.0. We'd like to apologize for any inconveniences caused by the breaking changes. Please don't forget we're always here for you and do let us know if you have any trouble upgrading, so we can help you!`}</p>
    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`Since this release, in preparation of 1.0 release, we annotated classes and packages which may have a chance of breaking change with the `}<inlineCode parentName="li">{`@UnstableApi`}</inlineCode>{` annotation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2445"
        }}>{`#2445`}</a></li>
      <li parentName="ul">{`The attribute access API of `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{` has been revamped for simplicity. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2322"
        }}>{`#2322`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`AttributeKey<String> MY_ATTR = AttributeKey.valueOf("MY_ATTR");
RequestContext ctx = ...;
ctx.setAttr(MY_ATTR, "foo");
assert "foo".equals(ctx.attr(MY_ATTR));
`}</code></pre>
      </li>
      <li parentName="ul">{`When a `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{` is derived or inherited from another `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{`, the parent context's attributes are now visible from the derived or inherited context: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2322"
        }}>{`#2322`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`AttributeKey<String> MY_ATTR = AttributeKey.valueOf("MY_ATTR");
ServiceRequestContext parentCtx = ...;
ClientRequestContext childCtx = ...;
assert childCtx.root() == parentCtx;
parentCtx.setAttr(MY_ATTR, "foo");
// Root context's attributes are visible from its children.
assert "foo".equals(childCtx.attr(MY_ATTR));
// Not visible anymore if the attribute is overwritten.
childCtx.setAttr(MY_ATTR, "bar");
assert "foo".equals(parentCtx.attr(MY_ATTR));
assert "bar".equals(childCtx.attr(MY_ATTR));
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`{Client,Service}RequestContext`}</inlineCode>{` now provides more useful ways to schedule request or response timeout: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2343"
        }}>{`#2343`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .service("/svc", myService.decorate((delegate, ctx, req) -> {
          if (req.headers().contains("x-extend-timeout")) {
              // Extend the timeout by 10 seconds.
              ctx.extendRequestTimeout(Duration.ofSeconds(10));
          }
          return delegate.serve(ctx, req);
      }));
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLog`}</inlineCode>{` API has been revamped for safety and usability. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2342"
        }}>{`#2342`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RequestContext ctx = ...;
// Asynchronous retrieval:
ctx.log().whenRequestComplete().thenAccept(log -> {
    // Can't access response properties at compilation level.
    assert log instanceof RequestOnlyLog;
    System.err.println(log.toStringRequestOnly());
})
ctx.log().whenComplete().thenAccept(log -> {
    // Can access all properties.
    assert log instanceof RequestLog;
    System.err.println(log.toStringResponseOnly());
});
ctx.log().whenAvailable(RequestLogProperty.SESSION).thenAccept(log -> {
    // Advanced use case:
    // Raises an exception if accessing an unavailable property.
    System.err.println(log.channel());
});
// Accessing a property ensuring availability:
ctx.log().ensureRequestComplete().requestEndTimeNanos();
ctx.log().ensureComplete().responseEndTimeNanos();
ctx.log().ensureAvailable(RequestLogProperty.RESPONSE_CONTENT)
   .responseContent();
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLog`}</inlineCode>{` also has a new property `}<inlineCode parentName="li">{`NAME`}</inlineCode>{`, which can be used as a method name in an RPC call, a span name in distributed tracing or any other human-readable string that can be used for identifying a request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2413"
        }}>{`#2413`}</a></li>
      <li parentName="ul">{`Added a new immutable API for encoding and decoding HTTP query strings: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2307"
        }}>{`#2307`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`QueryParams params = QueryParams.builder()
                                .add("foo", "1")
                                .add("bar", "2")
                                .build();
// Encoding
String queryString = params.toQueryString();
assert "foo=1&bar=2".equals(queryString);
// Decoding
QueryParams decodedParams = QueryParams.fromQueryString("foo=1&bar=2");
assert decodedParams.equals(params);
// Mutation
QueryParams newParams = params.toBuilder()
                              .add("baz", "3")
                              .build();
assert "foo=1&bar=2&baz=3".equals(newParams.toQueryString());
`}</code></pre>
      </li>
      <li parentName="ul">{`Added various convenient boolean getter methods to `}<inlineCode parentName="li">{`HttpStatus`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2435"
        }}>{`#2435`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`assert HttpStatus.CONTINUE.isInformational();
assert HttpStatus.OK.isSuccess();
assert HttpStatus.FOUND.isRedirect();
assert HttpStatus.BAD_REQUEST.isClientError();
assert HttpStatus.SERVICE_UNAVAILABLE.isServerError();
// No need to write like this anymore
assert HttpStatus.OK.codeClass() == HttpStatusClass.SUCCESS;
`}</code></pre>
      </li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`MediaTypeNames`}</inlineCode>{` which provides `}<inlineCode parentName="li">{`String`}</inlineCode>{` version of well known `}<inlineCode parentName="li">{`MediaType`}</inlineCode>{`s, which is useful when writing an annotated service: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2438"
        }}>{`#2438`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class MyAnnotatedService {
    @Get("/download/zip")
    @Produces(MediaTypeNames.ZIP)
    HttpResponse downloadArchive() { ... }
}
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now add `}<inlineCode parentName="li">{`{Request,Response}ConverterFunction`}</inlineCode>{` and `}<inlineCode parentName="li">{`ExceptionHandlerFunction`}</inlineCode>{` to all annotated services in your `}<inlineCode parentName="li">{`Server`}</inlineCode>{` easily. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2316"
        }}>{`#2316`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .annotatedService("/users", userService)
      .annotatedService("/posts", postService)
      .annotatedService("/files", fileService)
      // Applies all extensions to all 3 annotated services.
      .annotatedServiceExtensions(
          commonRequestConverters,
          commonResponseConverters,
          commonExceptionHandlers)
      .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now require a route to have HTTP headers and/or query parameters: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2102"
        }}>{`#2102`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      // Route to 'myService' only when:
      // - 'x-must-exist' header exists,
      // - and 'bar' query parameter exists.
      .route().get("/foo")
              .matchesHeaders("x-must-exist")
              .matchesParams("bar")
              .build(myService)
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now customize the `}<inlineCode parentName="li">{`SslContext`}</inlineCode>{` created from `}<inlineCode parentName="li">{`ServerBuilder.tlsSelfSigned()`}</inlineCode>{` or `}<inlineCode parentName="li">{`VirtualHost.tlsSelfSigned()`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2340"
        }}>{`#2340`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .tlsSelfSigned()
      .tlsCustomizer(sslCtxBuilder -> {
          sslCtxBuilder.ciphers(...);
      })
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now close an `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` asynchronously: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2430"
        }}>{`#2430`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsAddressEndpointGroup group =
    DnsAddressEndpointGroup.of("cluster.com", 8080);
group.whenClosed().thenRun(() -> {
    System.err.println("Closed!");
});
group.closeAsync();
`}</code></pre>
      </li>
      <li parentName="ul">{`You do not need to register your `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` to `}<inlineCode parentName="li">{`EndpointGroupRegistry`}</inlineCode>{` for client-side load balancing. Just specify it when you build a client: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2381"
        }}>{`#2381`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EndpointGroup group = EndpointGroup.of(
    Endpoint.of("node1.cluster.com"),
    Endpoint.of("node2.cluster.com"));
// Thrift
HelloService.Iface client =
    Clients.builder("tbinary+http", group)
           .path("/api/thrift/hello")
           .build(HelloService.Iface.class);
// gRPC
HelloServiceBlockingStub client =
    Clients.builder("gproto+http", group)
           .build(HelloServiceBlockingStub.class);
// Web
WebClient client =
    WebClient.of(SessionProtocol.HTTP, group);
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now limit the number of endpoints in `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{`, which is very useful when there are many candidate endpoints in the group but you want to send requests to only a few of them, to avoid unnecessarily large number of outbound connections: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2177"
        }}>{`#2177`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HealthCheckedEndpointGroup group =
    HealthCheckedEndpointGroup.builder(delegateGroup, "/health")
                              .maxEndpointCount(3)
                              .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now capture the `}<inlineCode parentName="li">{`ClientRequestContext`}</inlineCode>{` of your client call with `}<inlineCode parentName="li">{`ClientRequestContextCaptor`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2344"
        }}>{`#2344`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = WebClient.of("http://foo.com/");
try (ClientRequestContextCaptor ctxCaptor = Clients.newContextCaptor()) {
    HttpResponse res = client.get("/");
    ClientRequestContext ctx = ctxCaptor.get();
    ...
}
`}</code></pre>
      </li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`ClientFactory.insecure()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClientFactoryBuilder.tlsNoVerify()`}</inlineCode>{` to simplify testing SSL/TLS connections with self-signed certificates: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2340"
        }}>{`#2340`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Using the default insecure factory
WebClient.builder("https://127.0.0.1:8443")
         .factory(ClientFactory.insecure())
         .build();
// Using a custom insecure factory
WebClient.builder("https://127.0.0.1:8443")
         .factory(ClientFactory.builder()
                               .tlsNoVerify()
                               ...
                               .build())
         .build();
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientFactory`}</inlineCode>{` is now part of `}<inlineCode parentName="li">{`ClientOptions`}</inlineCode>{` for easier creation of derived clients. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2384"
        }}>{`#2384`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory factory = ...;
WebClient client =
    WebClient.builder(...)
             .factory(factory)
             .build();
WebClient clientCopy =
    WebClient.builder(...)
             .options(client.options())
             .build();
// Note that ClientFactory is copied as well.
assert client.factory() == clientCopy.factory();
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RetrofitMeterIdPrefixFunction`}</inlineCode>{` is now capable of adding HTTP method and request path pattern if you specify a Retrofit service class: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2356"
        }}>{`#2356`}</a></li>
      <li parentName="ul">{`New module `}<inlineCode parentName="li">{`armeria-dropwizard`}</inlineCode>{` provides the integration with `}<a parentName="li" {...{
          "href": "https://www.dropwizard.io/"
        }}>{`Dropwizard`}</a>{`, which allows you to leverage the best of the both worlds. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2236"
        }}>{`#2236`}</a>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "https://armeria.dev/docs/advanced-dropwizard-integration"
            }}>{`Using Armeria with Dropwizard`}</a>{` for more information.`}</li>
          <li parentName="ul">{`Special thanks to `}<a parentName="li" {...{
              "href": "https://github.com/cricket007"
            }}><strong parentName="a">{`@cricket007`}</strong></a>{` who volunteered for this.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now customize `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` when integrating with Spring framework by injecting `}<inlineCode parentName="li">{`DocServiceConfigurator`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2327"
        }}>{`#2327`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public DocServiceConfigurator docServiceConfigurator() {
    // Exclude all Thrift services from DocService.
    return docServiceBuilder -> {
        docServiceBuilder.exclude(DocServiceFilter.ofThrift());
    };
}
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ServerRule`}</inlineCode>{` (JUnit 4) and `}<inlineCode parentName="li">{`ServerExtension`}</inlineCode>{` (JUnit 5) now have more getters: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2449"
        }}>{`#2449`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Endpoint`}</inlineCode>{` getters:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`endpoint(SessionProtocol)`}</inlineCode>{`, `}<inlineCode parentName="li">{`httpEndpoint()`}</inlineCode>{` and `}<inlineCode parentName="li">{`httpsEndpoint()`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`URI`}</inlineCode>{` getters:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`uri(SessionProtocol)`}</inlineCode>{`, `}<inlineCode parentName="li">{`uri(SessionProtocol, SerializationFormat)`}</inlineCode>{`, `}<inlineCode parentName="li">{`httpUri()`}</inlineCode>{`, `}<inlineCode parentName="li">{`httpUri(SerializationFormat)`}</inlineCode>{`, `}<inlineCode parentName="li">{`httpsUri()`}</inlineCode>{` and `}<inlineCode parentName="li">{`httpsUri(SerializationFormat)`}</inlineCode></li>
              <li parentName="ul">{`The old deprecated getters return `}<inlineCode parentName="li">{`String`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`URI`}</inlineCode>{`.`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`InetSocketAddress`}</inlineCode>{` getters:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`socketAddress(SessionProtocol)`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{`s returned by our API will leave a warning log like the following when you perform a blocking operation in an event loop thread: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2275"
        }}>{`#2275`}</a>
        <pre parentName="li"><code parentName="pre" {...{}}>{`Calling a blocking method on CompletableFuture from an event loop or
non-blocking thread. You should never do this ...
java.lang.IllegalStateException: Blocking event loop, don't do this.
`}</code></pre>
        {`You can disable this functionality by specifying the `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.reportBlockedEventLoop=false`}</inlineCode>{` JVM option.`}</li>
      <li parentName="ul">{`You can now serialize and deserialize `}<inlineCode parentName="li">{`ThriftCall`}</inlineCode>{`, `}<inlineCode parentName="li">{`ThriftReply`}</inlineCode>{`, `}<inlineCode parentName="li">{`TMessage`}</inlineCode>{` and `}<inlineCode parentName="li">{`TBase`}</inlineCode>{` into TTEXT JSON using `}<inlineCode parentName="li">{`ThriftJacksonModule`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2439"
        }}>{`#2439`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ObjectMapper mapper = new ObjectMapper();
mapper.registerModule(new ThriftJacksonModule());
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now make any SLF4J `}<inlineCode parentName="li">{`Logger`}</inlineCode>{` context-aware with `}<inlineCode parentName="li">{`RequestContext.makeContextAware(Logger)`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2341"
        }}>{`#2341`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Prints 'Hello!'
Logger logger = ...;
logger.info("Hello!");
// Prints '[<current context>] Hello!'
Logger ctxAwareLogger = ctx.makeContextAware(logger);
ctxAwareLogger("Hello!");
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextExporter`}</inlineCode>{` is now part of the core API, allowing you to integrate with other logging frameworks than Logback, such as Log4J2. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2314"
        }}>{`#2314`}</a></li>
      <li parentName="ul">{`You can now disable HTTP header validation the the `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.validateHeaders=false`}</inlineCode>{` JVM option.`}</li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Slightly reduced memory footprint of `}<inlineCode parentName="li">{`Logging{Client,Service}`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2341"
        }}>{`#2341`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`UnknownHostException`}</inlineCode>{` raised by Armeria now explains what DNS query has failed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2332"
        }}>{`#2332`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`WebClient`}</inlineCode>{` now accepts a URI that starts with `}<inlineCode parentName="li">{`none+`}</inlineCode>{` as well. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2361"
        }}>{`#2361`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` now logs a helpful warning message when it receives a 4XX response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2401"
        }}>{`#2401`}</a></li>
      <li parentName="ul">{`Our builder API Javadoc does not show mysterious return type parameter such as `}<inlineCode parentName="li">{`B`}</inlineCode>{` and `}<inlineCode parentName="li">{`SELF`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2454"
        }}>{`#2454`}</a></li>
      <li parentName="ul">{`A client now gets `}<inlineCode parentName="li">{`SSLException`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`ClosedSessionException`}</inlineCode>{` if a connection attempt fails with an SSL/TLS handshake error. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2338"
        }}>{`#2338`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`WebClient`}</inlineCode>{` does not omit a request query string when sending a request to an absolute URI. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2309"
        }}>{`#2309`}</a></li>
      <li parentName="ul">{`A user cannot start a `}<inlineCode parentName="li">{`Server`}</inlineCode>{` with a misconfigured `}<inlineCode parentName="li">{`SslContext`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2325"
        }}>{`#2325`}</a></li>
      <li parentName="ul">{`A user now always gets the correct `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{` even if the contexts are nested. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1083"
        }}>{`#1083`}</a></li>
      <li parentName="ul">{`Fixed a bug where thread-local context customizers were called for derived contexts unintentionally. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2344"
        }}>{`#2344`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Clients.withHttpHeaders()`}</inlineCode>{` and `}<inlineCode parentName="li">{`withContextCustomizer()`}</inlineCode>{` now work with gRPC calls. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2344"
        }}>{`#2344`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext.path()`}</inlineCode>{` now returns a correct path for gRPC client calls. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2344"
        }}>{`#2344`}</a></li>
      <li parentName="ul">{`You can now send a POST request with an empty body with `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` client. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2357"
        }}>{`#2357`}</a></li>
      <li parentName="ul">{`Server-side route cache hit ratio was not as good as we intended. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2358"
        }}>{`#2358`}</a></li>
      <li parentName="ul">{`Fixed various potential memory leak in `}<inlineCode parentName="li">{`HttpResponseWriter`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2359"
        }}>{`#2359`}</a></li>
      <li parentName="ul">{`Long-polling health check mechanism now detects a stall server which accepts a connection but does not send any response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2392"
        }}>{`#2392`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientFactoryOptions`}</inlineCode>{` does not raise a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2387"
        }}>{`#2387`}</a></li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`AsyncMethodCallback`}</inlineCode>{` specified when making an async Thrift call now has thread-local `}<inlineCode parentName="li">{`ClientRequestContext`}</inlineCode>{` set properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2383"
        }}>{`#2383`}</a></li>
      <li parentName="ul">{`gRPC client and server now works well with non-official gRPC stub generators such as `}<a parentName="li" {...{
          "href": "https://github.com/salesforce/reactive-grpc/"
        }}>{`reactive-grpc`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2376"
        }}>{`#2376`}</a></li>
      <li parentName="ul">{`Fixed a bug where a `}<inlineCode parentName="li">{`Server`}</inlineCode>{` can be started back after `}<inlineCode parentName="li">{`close()`}</inlineCode>{` is called. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2406"
        }}>{`#2406`}</a></li>
      <li parentName="ul">{`Fixed a regression where Reactor does not treat Armeria's event loop threads as non-blocking. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2404"
        }}>{`#2404`}</a></li>
      <li parentName="ul">{`Armeria does not fail to initialize anymore even if it failed to load the `}<inlineCode parentName="li">{`com.linecorp.armeria.versions.properties`}</inlineCode>{` file. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2398"
        }}>{`#2398`}</a></li>
      <li parentName="ul">{`You'll not see the `}<inlineCode parentName="li">{`cannot start a new stream with a DATA frame`}</inlineCode>{` errors anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2429"
        }}>{`#2429`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLog.requestStartTime`}</inlineCode>{` property now includes the time taken for making a connection attempt and the time taken by decorators. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2436"
        }}>{`#2436`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.dumpOpenSslInfo=true`}</inlineCode>{` JVM option does not trigger a `}<inlineCode parentName="li">{`StackOverflowError`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2418"
        }}>{`#2418`}</a></li>
      <li parentName="ul">{`Fixed cosmetic issues in `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` client sidebar. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2470"
        }}>{`#2470`}</a></li>
      <li parentName="ul">{`Made sure IPv6 DNS queries are not sent to some IPv4 only machines with a link-local IPv6 interface. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2464"
        }}>{`#2464`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HttpParameters`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`QueryParams`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2307"
        }}>{`#2307`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.tls()`}</inlineCode>{` methods that require a `}<inlineCode parentName="li">{`Consumer`}</inlineCode>{` have been deprecated. Use `}<inlineCode parentName="li">{`tlsCustomizer(Consumer)`}</inlineCode>{` instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2340"
        }}>{`#2340`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
Server.builder()
      .tls(..., sslCtxBuilder -> { ... });
// After:
Server.builder()
      .tls(...)
      .tlsCustomizer(sslCtxBuilder -> { ... });
`}</code></pre>
      </li>
      <li parentName="ul">{`Many classes which have `}<inlineCode parentName="li">{`Http`}</inlineCode>{` in their names have been deprecated in favor of those without `}<inlineCode parentName="li">{`Http`}</inlineCode>{`, e.g. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2323"
        }}>{`#2323`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`RetryingHttpClient`}</inlineCode>{` -> `}<inlineCode parentName="li">{`RetryingClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`HttpFileService`}</inlineCode>{` -> `}<inlineCode parentName="li">{`FileService`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Many builder classes' constructors have been deprecated in favor of `}<inlineCode parentName="li">{`builder()`}</inlineCode>{` static methods, e.g. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1719"
        }}>{`#1719`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`new ClientBuilder()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`Clients.builder()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`new ArmeriaRetrofitBuilder()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`ArmeriaRetrofit.builder()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`new ServerCacheControlBuilder()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`ServerCacheControl.builder()`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Many public static final fields that are not truly constants have been deprecated in favor of static factory methods, e.g.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`EndpointSelectionStrategy.ROUND_ROBIN`}</inlineCode>{` -> `}<inlineCode parentName="li">{`roundRogin()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`NodeValueCodec.DEFAULT`}</inlineCode>{` -> `}<inlineCode parentName="li">{`ofDefault()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`AuthTokenExtractors.BASIC`}</inlineCode>{` -> `}<inlineCode parentName="li">{`basic()`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`MoreNamingConventions`}</inlineCode>{` has been deprecated because we follow Micrometer's recommended naming convention. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2367"
        }}>{`#2367`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Version.identify()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`getAll()`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2398"
        }}>{`#2398`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ServiceRequestContext.setRequestTimeout*()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`extendRequestTimeout*()`}</inlineCode>{`, `}<inlineCode parentName="li">{`setRequestTimeoutAfter*()`}</inlineCode>{`, `}<inlineCode parentName="li">{`setRequestTimeoutAt*()`}</inlineCode>{` and `}<inlineCode parentName="li">{`clearRequestTimeout()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2343"
        }}>{`#2343`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext.setResponseTimeout*()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`extendResponseTimeout*()`}</inlineCode>{`, `}<inlineCode parentName="li">{`setResponseTimeoutAfter*()`}</inlineCode>{`, `}<inlineCode parentName="li">{`setResponseTimeoutAt*()`}</inlineCode>{` and `}<inlineCode parentName="li">{`clearResponseTimeout()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2343"
        }}>{`#2343`}</a></li>
      <li parentName="ul">{`Many methods that return a `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` have been renamed from `}<inlineCode parentName="li">{`*Future()`}</inlineCode>{` to `}<inlineCode parentName="li">{`when*()`}</inlineCode>{`, e.g. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2427"
        }}>{`#2427`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`HttpRequest.completionFuture()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`whenComplete()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`HttpResponseWriter.onDemand()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`whenConsumed()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`EndpointGroup.initialEndpointsFuture()`}</inlineCode>{` -> `}<inlineCode parentName="li">{`whenReady()`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Many URI-returning methods in `}<inlineCode parentName="li">{`ServerRule`}</inlineCode>{` and `}<inlineCode parentName="li">{`ServerExtension`}</inlineCode>{` have been deprecated in favor of the new methods that do not require a path parameter: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2449"
        }}>{`#2449`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerExtension server = ...;
// Before
server.httpUri("/");
server.httpUri("/foo");
// After
server.httpUri();
server.httpUri().resolve("/foo");
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`THttpService.allowedSerializationFormats()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`supportedSerializationFormats()`}</inlineCode>{` for consistency with `}<inlineCode parentName="li">{`GrpcService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2453"
        }}>{`#2453`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Service.decorate(Class)`}</inlineCode>{` has been deprecated in favor of other `}<inlineCode parentName="li">{`decorate()`}</inlineCode>{` methods that require a function.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ClosedPublisherException`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`ClosedStreamException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2468"
        }}>{`#2468`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Most revamped APIs in this release were changed in a backward-incompatible way:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`RequestLog`}</inlineCode></li>
          <li parentName="ul">{`Attribute API in `}<inlineCode parentName="li">{`RequestContext`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Content previewing mechanism has been revamped into decorators. Use `}<inlineCode parentName="li">{`ContentPreviewingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`ContentPreviewingService`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`You cannot add callbacks to `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{` anymore because we found this feature results in poor performance and confusing behavior in many cases. We may want to revisit this feature if there is a valid use case for it.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`{Server,VirtualHost}Builder.tls()`}</inlineCode>{` methods do not throw an `}<inlineCode parentName="li">{`SSLException`}</inlineCode>{` anymore. `}<inlineCode parentName="li">{`build()`}</inlineCode>{` will throw an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` instead. As a result, any SSL configuration failure will be known when a user calls `}<inlineCode parentName="li">{`build()`}</inlineCode>{`, rather than `}<inlineCode parentName="li">{`tls()`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`We were not able to keep some classes or method signatures while we remove `}<inlineCode parentName="li">{`Http`}</inlineCode>{` from class names.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ServiceRequestContext.logger()`}</inlineCode>{` has been removed due to performance issues with Log4J2.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext.isTimedOut()`}</inlineCode>{` has been removed.`}</li>
      <li parentName="ul">{`We do not support Tomcat 8.0 anymore, which was obsoleted by Tomcat 8.5 anyway.`}</li>
      <li parentName="ul">{`The classes in `}<inlineCode parentName="li">{`armeria-grpc-protocol`}</inlineCode>{` have been reorganized into multiple packages.`}</li>
      <li parentName="ul">{`Our Micrometer meter IDs have been changed, which means you might need to update your monitoring configuration. If you wish to switch back to the legacy naming style, specify the `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.useLegacyMeterNamed=true`}</inlineCode>{` JVM option. However, please keep in mind this option will eventually go away, because the new naming convention is recommended by Micrometer.`}</li>
      <li parentName="ul">{`All our methods do not return `}<inlineCode parentName="li">{`Optional`}</inlineCode>{` anymore. They are all `}<inlineCode parentName="li">{`@Nullable`}</inlineCode>{` now. If you wish to continue using `}<inlineCode parentName="li">{`Optional`}</inlineCode>{`, just wrap the return value with `}<inlineCode parentName="li">{`Optional.ofNullable()`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`EndpointGroupRegistry`}</inlineCode>{` has been removed, because you can now just specify an `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{` directly when creating a client.`}
        <ul parentName="li">
          <li parentName="ul">{`As a result, you need to specify an `}<inlineCode parentName="li">{`EndpointSelectionStrategy`}</inlineCode>{` when building an `}<inlineCode parentName="li">{`EndpointGroup`}</inlineCode>{`. If unspecified, `}<inlineCode parentName="li">{`EndpointSelectionStrategy.weightedRoundRobin()`}</inlineCode>{` is used.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`MeterIdPrefixFunction`}</inlineCode>{` is not a functional interface anymore. You must implement two methods explicitly: `}<inlineCode parentName="li">{`activeRequestPrefix()`}</inlineCode>{` and `}<inlineCode parentName="li">{`completeRequestPrefix()`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Now that `}<inlineCode parentName="li">{`ClientFactory`}</inlineCode>{` is a part of `}<inlineCode parentName="li">{`ClientOption`}</inlineCode>{`, the following code will not work as expected, because `}<inlineCode parentName="li">{`options(options)`}</inlineCode>{` will overwrite the factory.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientOptions options = ClientOptions.builder().maxResponseLength(...).build();
ClientFactory factory = ClientFactory.builder().useHttp2Preface(false).build();
WebClient client = WebClient.builder(...)
                            .factory(factory)
                            .options(options)
                            .build();
// This will fail!
assert client.options().factory() == factory;
`}</code></pre>
        {`To fix this, you must call `}<inlineCode parentName="li">{`options()`}</inlineCode>{` first, and then override the individual properties:`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = WebClient.builder(...)
                            .options(options)
                            .factory(factory)
                            .build();
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`StreamMessage.subscribe(..., boolean withPooledObjects)`}</inlineCode>{` has been removed. Use `}<inlineCode parentName="li">{`subscribe(..., SubscriptionOption.WITH_POOLED_OBJECTS)`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`StreamMessage.drainAll(..., boolean withPooledObjects)`}</inlineCode>{` has been removed. Use `}<inlineCode parentName="li">{`drainAll(..., SubscriptionOption.WITH_POOLED_OBJECTS)`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpRequestDuplicator`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpResponseDuplicator`}</inlineCode>{` are now interfaces. Use `}<inlineCode parentName="li">{`HttpRequest.toDuplicator()`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpResponse.toDuplicator()`}</inlineCode>{` to create a duplicator.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`StructuredLog`}</inlineCode>{` and `}<inlineCode parentName="li">{`StructuredLoggingService`}</inlineCode>{` have been removed. Use `}<inlineCode parentName="li">{`AccessLogWriter`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ThriftStructuredLogJsonFormat`}</inlineCode>{` has been removed. Register `}<inlineCode parentName="li">{`ThriftJacksonModule`}</inlineCode>{` to `}<inlineCode parentName="li">{`ObjectMapper`}</inlineCode>{` to serialize or deserialize Thrift objects.`}</li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.9.1 -> 5.9.3`}</li>
      <li parentName="ul">{`Dropwizard 1.3.17 -> 1.3.18`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.1 -> 4.1.2`}</li>
      <li parentName="ul">{`gRPC 1.25 -> 1.27`}</li>
      <li parentName="ul">{`Jackson 2.10.1 -> 2.10.2.20200130`}</li>
      <li parentName="ul">{`java-jwt 3.8.3 -> 3.9.0`}</li>
      <li parentName="ul">{`Jetty 9.4.24 -> 9.4.26`}</li>
      <li parentName="ul">{`Micrometer 1.3.2 -> 1.3.3`}</li>
      <li parentName="ul">{`Netty 4.1.43 -> 4.1.45`}
        <ul parentName="li">
          <li parentName="ul">{`TCNative BoringSSL 2.0.26 -> 2.0.28`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Prometheus Java client 0.8.0 -> 0.8.1`}</li>
      <li parentName="ul">{`Reactor 3.3.1 -> 3.3.2`}</li>
      <li parentName="ul">{`Retrofit 2.6.2 -> 2.7.1`}</li>
      <li parentName="ul">{`RxJava 2.2.15 -> 2.2.17`}</li>
      <li parentName="ul">{`SLF4J 1.7.29 -> 1.7.30`}</li>
      <li parentName="ul">{`Spring Boot 2.2.1 -> 2.2.4, 2.1.10 -> 2.1.12`}</li>
      <li parentName="ul">{`Thrift 0.12.0 -> 0.13.0`}</li>
      <li parentName="ul">{`Tomcat 9.0.29 -> 9.0.30, 8.5.49 -> 8.5.50`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['4whomtbts', 'abkura', 'andrey-tpt', 'anuraaga', 'arhont375', 'cbornet', 'Charliocat', 'codefromthecrypt', 'cricket007', 'Cronnay', 'danada', 'dylmeadows', 'eonezhang', 'franz1981', 'geminiKim', 'heowc', 'hyangtack', 'ikhoon', 'ildartuykin', 'imasahiro', 'jcchavezs', 'jrhee17', 'KarboniteKream', 'kezhenxu94', 'kojilin', 'linxGnu', 'mauhiz', 'max904-github', 'mercanil', 'minwoox', 'monorisk', 'njhill', 'perlun', 'renaudb', 'rmichela', 'sivaalli', 'skkap', 'snaiper80', 'southernkasaist', 'Stupremee', 'syleeeee', 'tobias-', 'trustin', 'ylgrgyq']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      